body{font-family: 'Open Sans', sans-serif !important;}


.logo{width:13.5VW}
.logofooter{width:200px;}


.nav_menu{
  text-transform: uppercase;
}

.nav_menu>*{width:130px;text-align:center;}

.q_links_ttle{
  font-weight: bold;
  font-size:1.5em;
  color:#e46634;
}

.header{background:#dddae2 !important;}

.footer{background:#001529 !important;border-top:20px solid #001529;color:rgb(212, 209, 209) !important;}

.footer_links a{
  display:flex;
  justify-content: flex-start;
  flex-direction:column;
  align-items: left;
  font-size:1.1em !important;
  color:rgb(218, 213, 213);
  padding-left:15px;
}

.footer_links>a:hover{
  text-decoration: underline;
}

.nav_menu>*:hover{background-color: #263868 !important;color:#e8eaf7;}
.maintain{
  display:flex;
  align-items: center;
}

.rights{
  text-align:center;
  padding-top:50px;
  font-size:.9em;
}

.about{
  padding:128px 0 0 0;
}

.contacts_cont{
  padding: 155px 0 0 0;
}
.home_cont{padding: 129px 0 0 0;}

.vision_mission{
  font-size:1.2em !important;
  color:rgb(104, 104, 104)
}

.contacts{
  padding-top:10VH;
}

.headOffice{
  padding:7VH 30px 100px 30px;
}

.tosts{background-color:transparent;}
.tosts img{
  height:120px;
}

.association{padding:30px 0 50px;}

.association h1{
  font-size: 3em;
  font-weight: bold;
  color: green;
  z-index: 100;
}

.association_cont{
  background:rgb(247, 223, 214)
}

.contact_card{
  height:280px;
}

.devider{
  border:1px solid rgb(197, 195, 195);
  /* display:none !important; */
}

.contact_ttl{font-size:2.2em;color:#fff;}

.slideshow{
  /* background:cadetblue !important; */
  /* position:relative; */
}


.brands{
  padding:80px 80px;
  background: linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.25) 200%);
 background-blend-mode: multiply;
}

.brands img{
  width:200px;
  padding:20px;
  filter:grayscale();
}

.slide{
  height:60VH;
  display: block;
}

.slide img{
  width:100%;
}

.slide h1{
  font-size:3em;
  font-weight:bold;
  color:rgb(255, 255, 255);
  position:absolute;
  text-align: center;
  top:0%;
  padding-left:15px;
}

.slide h2{
  font-size:1.4em;
  /* font-weight:bold; */
  color:rgb(255, 255, 255);
  position:absolute;
  text-align: center;
  top:55%;
  padding-left:15px;
}

.homeabt{width:60%;}

.overlay{
  position:absolute;
  top:70%;
  left:5%;
  z-index: 100;
}

.projects{
  padding-top:64px;
}

.projects_txt{padding:25px;}

.projects_txt:nth-child(odd) h1, 
.projects_txt:nth-child(odd) h2, 
.projects_txt:nth-child(odd) h3{
  text-align: right;
}

.projects_txt:nth-child(even) h1, 
.projects_txt:nth-child(even) h2, 
.projects_txt:nth-child(even) h3{
  text-align: left;
}


.projects_txt:nth-child(odd)  a{float:right}
.projects_txt:nth-child(even) a{float:left}

.projects_txt:nth-child(odd) h1, .projects_txt:nth-child(even) h1{ font-size:2.5em;color:#fff;}
.projects_txt:nth-child(odd) h2, .projects_txt:nth-child(even) h2{ color:#e46634}
.projects_txt:nth-child(odd) h3, .projects_txt:nth-child(even) h3{ color:#cecbca}

.projects_img{
  width:100%;
}

.project_row:nth-child(odd){
  background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(37,145,251,0.98) 0.1%, rgba(0,7,128,1) 99.8% );
}

.project_row:nth-child(even){
  background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(37,145,251,0.98) 0.1%, rgba(0,7,128,1) 99.8% );
}

.nomatch{
  padding-top:128px;
}

.overlay_bg{
  display:block;
  padding:60px 300px;
  background:rgb(0, 0, 0);
  opacity: .7;
  border-radius:10px 40px
}

.next_btn, .previous_btn {
  position: absolute;
  display: block;
  padding: 5px;
  font-size: 2.5em;
  line-height: 0;
  top: 50%;
  -webkit-transform: translate(0,-50%);
  -ms-transform: translate(0,-50%);
  transform: translate(0,-50%);
  cursor: pointer;
  color: rgb(255, 255, 255);
  border: none;
  outline: 0;
  background:rgb(75, 73, 73);
  z-index:1 !important;
  border-radius: 100%;
  opacity: .1;
}

.next_btn:hover, .previous_btn:hover { opacity:1;}
.next_btn {right: 25px;}
.previous_btn {left: 25px;}

.chairman{
  width:20vw;
  flex-wrap: wrap;
  float:left;
}

.about_img{
  width: 21vw;
  padding-top: 145px;
  display: block;
  float: right;
  margin-right: 45px;
}


.about h1{
  font-size:2.3em;
  font-size: bolder;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.about h4{font-weight: bold;}

.home_about{
  padding: 50px 25px 70px 185px;
  background: linear-gradient(90deg, #1CB5E0 0%, #000851 100%);
}

.home_about h1{
  font-size: 3em;
  padding: 20px 0;
  color:azure;
}

.home_about h3{
  font-size: 1.2em;
  font-weight: bold;
}

.home_about p{
  font-size: 1.2em;
  text-align: justify;
}

.about_chairman{
  color:rgb(212, 209, 209);
  padding-left:30px;
}

.home_strategic{
  /* background-color:#1CB5E0;


  background-image: url("./bg.jpg");
  background-repeat:repeat-x;
  background-size:contain; */
  padding:80px 0px;
  /* height:620px; */
  display: block;

  /* background-image: linear-gradient(to right, #434343 0%, black 100%); */

}

.strategic-col{
  background: linear-gradient(-180deg, #BCC5CE 0%, #929EAD 98%), radial-gradient(at top left, rgba(255,255,255,0.30) 0%, rgba(0,0,0,0.30) 100%);
  background-blend-mode: screen;
}

.strategic_header{
  text-align: center;
  padding-bottom: 50px;
}

.strategic_header h1{
  font-size:3em;
}


.card_slide{
    display: block;;
    /* height: 300px; */
}



/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (tablets, 768px and up) */
@media only screen  and (max-width:576px) { 
  .content{padding-top:60px !important;}
  .slide img{
    width:100%;
  }
  .home_cont{padding:0px;}

  .next_btn, .previous_btn {top: 30%;}
  .overlay {top: 66%;}
  .slide h1 {font-size: 1.5em;}
  .slide h2 {
    font-size: .9em;
    top: 53%;}
  .overlay_bg {
      padding: 27px 300px;
      border-radius: 8px 9px;
  }
  .logo {width: 42.5VW;}
  .slide {height:152px;}
  .home_about{padding: 0px 25px 70px 20px;}
  .home_about h1 {
    font-size: 1.6em;
    /* padding: 20px 0; */
    text-align: center;
  }
  .homeabt{width:100%;}
  .about_img {
    width: 69vw;
    padding-top: 21px;
    margin-right: 45px;
  }
  .forMobile{
    padding-top:126px;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) { }

/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) { }